////////// COLORS Bootstrap //////////
$pink: #e5408f;
$orange: #ff5745;
$yellow: #f9c648;
$purple: #3c255e;
$green: #4fab89;
$blue: #0867ff;
$dark: #222222;
$light: #f8f8f8;
$white: #fff;
$gray: #808080;
$body-color: $dark;
$theme-colors: (
  "primary": $orange,
  "secondary": $white,
  "success": $blue,
);
$primary: $orange;
$secondary: $purple;

$border-radius: 10px;
$border-radius-lg: 20px;
$border-color: #b0b0b0;

$enable-responsive-font-sizes: true;

.text-pink {
  color: $pink;
}

.text-lg {
  font-size: 1.875rem;
  line-height: 1.15;
}

.text-md {
  font-size: 1.25rem;
  line-height: 1.2;
}

.text-xs {
  font-size: 0.75rem;
}

.text-xxs {
  font-size: 0.625rem;
  line-height: 1;
}

.bg-gray {
  background-color: $gray;
}

.bg-pink {
  background-color: $pink;
}

.text-truncate-2 {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media (max-width: 767px) {
  .text-sm-xxs {
    font-size: 0.625rem;
    line-height: 1.2;
  }
}

////////// Fonts //////////
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'),
    url('/assets/fonts/Inter-Regular.woff') format('woff'),
    url('/assets/fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  src: local('Inter Bold'),
    url('/assets/fonts/Inter-Bold.woff') format('woff'),
    url('/assets/fonts/Inter-Bold.woff2') format('woff2');
}

$primary-font: 'Inter', sans-serif;
$secondary-font: 'Inter', sans-serif;

$font-family-sans-serif: $primary-font;
$headings-font-family: $secondary-font;

$h1-font-size: 2.75rem;
$h2-font-size: 2.375rem;
$h3-font-size: 1.625rem;
$h4-font-size: 1.375rem;
$h5-font-size: 1.125rem;

$headings-line-height: 1.1;
$headings-font-weight: 700;

h1.smaller {
  font-size: 1.5rem;
}

.text-white a,
.text-white a:hover {
  color: $white;
}

a {
  transition: .3s;
}


/// START: To be deleted when all icons are replaced with new custom icon font ///
svg.feather,
i-feather svg {
  vertical-align: text-bottom;
}

small i-feather,
.small i-feather,
i-feather.small {
  width: 20px;
  height: 20px;
}

.large i-feather,
i-feather.large {
  width: 50px;
  height: 50px;
}

/// END: To be deleted when all icons are replaced with new custom icon font ///

////////// BUTTONS Bootstrap //////////
$btn-font-weight: 700;
$btn-padding-y: .15rem;
$btn-padding-x: 1.5rem;

// btn-lg
$btn-padding-y-lg: .75rem;
$btn-border-radius-lg: $border-radius;
$btn-font-size-lg: 1rem;

// btn-sm
$btn-border-radius-sm: $border-radius;

.btn {
  &.btn-secondary {
    border: 1px solid $dark;
  }
}

// Buttons with icon (btn-icon)
.btn.btn-icon {
  position: relative;
  display: flex;
  padding-left: 3.813rem;

  span[class^="vi-"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    max-width: 3.063rem;
    padding: 0.313rem 1rem;
    margin-right: 0.75rem;
    font-size: 1.125rem;
    z-index: 1;
  }

  &.btn-secondary span[class^="vi-"] {
    border-right: 1px solid;
  }

  &.btn-secondary:hover span[class^="vi-"] {
    border-color: transparent;
  }

  .btn-icon-text {
    flex: 1 0 auto;
    text-align: center;
  }
}

// Other buttons

button {
  font-size: 14px;
}

.btn-close {
  line-height: 1;

  &.position-absolute {
    right: 0;
    top: 0;
  }
}

.container__uploaded-images .btn--delete-image {
  float: right;
  transform: translate(0, -50%);
  background-color: #fff;

  &:hover {
    background-color: $pink;
  }
}

/////////// CAROUSEL Bootstrap ////////////
$carousel-indicator-width: 8px;
$carousel-indicator-height: 8px;
$carousel-indicator-spacer: 3px;
$carousel-indicator-active-bg: #d9d9d9;

.carousel-indicators {
  li {
    border: none !important;
    border-radius: 8px;
    opacity: 1 !important;

    &.active {
      background-color: $primary;
    }
  }
}

.carousel-arrows .carousel-control {
  display: flex !important;
}

////////// GENERAL STYLES //////////
main {
  position: relative;
}

$hr-border-color: #cbcbcb;

$box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.165);

.page-body-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;

  &.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

.w-430 {
  max-width: 430px;
}

// Columns
.columns4-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}

@media screen and (min-width: 768px) {
  .columns4-2 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }

  .columns2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

// Lists
$list-inline-padding: 1.4rem;

.list-inline .list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.list-inline li:not(:last-child)::after {
  content: '•';
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: text-bottom;
}

.list-icons {
  line-height: 1.2;
}

.list-icons li {
  position: relative;
  padding: 0 0 10px 30px;
}

.list-icons li i-feather {
  position: absolute;
  top: 0;
  left: 0;
}

// Badge
$badge-pill-padding-x: 1rem;
$badge-pill-padding-x: 1rem;

.badge {
  min-width: 95px;
}

////////// NAVBAR //////////
$dropdown-border-color: #fff;

.navbar-nav .dropdown-menu {
  position: absolute;
  box-shadow: $box-shadow;
}

////////// SIDEBAR //////////
// $sidebar-width-lg: 260px;

$sidebar-width-lg: 220px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;

// Forms
$input-group-addon-bg: $white;
$custom-file-text: (
  es: "Elegir",
  en: "Browse"
);

.maker-admin-form .form-group>label {
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: top;
}

.custom-checkbox label.custom-control-label {

  &::before,
  &::after {
    top: 50%;
    transform: translate(0, -50%);
  }

  &::before {
    border-radius: 3px;
  }
}

.custom-file-label,
.pointer {
  cursor: pointer;
}

////////// SIDEBAR //////////

.card {

  .card-header {
    background-color: $white;
  }
}

// Ng Select
.ng-select .ng-select-container {
  border-radius: $border-radius;
  padding: 0.375rem 0.75rem;
}

.ng-select.ng-select-opened>.ng-select-container {
  border-color: $pink;
  box-shadow: 0 0 0 2px rgba($pink, 0.2);
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: 0.375rem;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  width: 10px !important;
  height: 10px !important;
}

.ng-select .ng-clear-wrapper {
  right: -16px;
  height: 18px;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.5rem;
}

.ng-select.custom-simple-select {
  width: 100%;
  max-width: 100%;

  ::ng-deep {
    .ng-select-container {
      padding: 0;
      border: none !important;
      background-color: transparent;
      box-shadow: none !important;

      .ng-value-container {
        font-weight: 700;

        .ng-placeholder,
        .ng-value {
          color: $dark;
        }

        .ng-input {
          padding-left: 0;
          padding-right: 20px;

          input {
            font-weight: 700;
            color: $dark;
          }

        }
      }
    }

    .ng-arrow {
      color: $dark;
      border-width: 2px 2px 0 0;
    }
  }
}

// ngx-datatable styles

::ng-deep datatable-scroller {
  width: 100% !important;
}

::ng-deep .ngx-datatable.bootstrap {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22px !important;
}

::ng-deep .ngx-datatable.bootstrap .datatable-header,
::ng-deep .ngx-datatable.bootstrap .datatable-header>* {
  min-height: 90px !important;
  align-items: center !important;
  background-color: #f9fbfd !important;
}

::ng-deep .ngx-datatable .datatable-body .datatable-row-wrapper,
::ng-deep .datatable-body-row {
  min-height: 90px !important;
  align-items: center !important;
}

::ng-deep .ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  font-weight: 400 !important;
}

::ng-deep .ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
  border-bottom: none !important;
  padding-left: 2rem;
}

::ng-deep .ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
  padding-left: 2rem;
}

body .ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
  background-color: #fff;
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
  font-weight: 600;
}

.ngx-datatable.bootstrap .datatable-footer {
  border-top: 2px solid #d1d4d7;
  font-weight: 600;
  color: $dark !important;
  background-color: #fff !important;
}

body .ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
  color: #212529;
}

body .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
body .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
  background-color: $light;
}

.ngx-datatable .btn-sm .fa {
  width: 13px;
}

// TEMPORAL
.ngx-datatable.bootstrap .btn {
  margin-bottom: 30px;
}

// Divider //
.divider-or {
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 50%;
    padding: 5px 20px;
    color: #bbb;
    background-color: $white;
    transform: translate(-50%, -50%);
  }
}

// Modal //
.modal {
  .modal-content {
    border: none;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  .modal-dialog {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0 auto;
  }
}

::ng-deep .modal-backdrop {
  background-color: #fff;

  &.show {
    opacity: .95;
  }
}


/// Header ///
.nav-item .dropdown-item:active {
  color: $dark;
  background-color: #f2f2f2;
}

a.nav-link,
.navbar-light .navbar-nav a.nav-link {
  color: $dark;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  .navbar-expand-lg .navbar-nav.flex-row .nav-link {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
}

/// Cookie banner ///
.cc_dialog.simple {
  max-width: 100%;
  font-family: $primary-font;
}

.cc_cp_f_save button,
.light.cc_dialog button.cc_b_ok,
.light.cc_dialog button.cc_b_cp {
  border-radius: 30px;
  font-family: $secondary-font;
  text-transform: uppercase;
}

.light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container .cc_cp_footer .cc_cp_f_save button,
.light.cc_dialog button.cc_b_ok {
  background-color: $primary;
}

.light.cc_dialog button.cc_b_cp {
  border: 2px solid;
}

.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog,
.cc_css_reboot a {
  font-family: $primary-font;
}

.light.cookie-consent-preferences-overlay .cookie-consent-preferences-dialog .cc_cp_container {
  border-radius: 10px;
  overflow: hidden;
}

.cc_cp_m_menu li:nth-child(3),
.cc_cp_m_menu li:nth-child(5) {
  display: none !important;
}

.cc_cp_f_powered_by {
  visibility: hidden !important;
}

// Min height for the text editor content (ckeditor)
.ck-content {
  p {
    min-height: 100px;
  }
}