@font-face {
  font-family: 'venntur-icons';
  src: url('/assets/fonts/venntur-icons/venntur-icons.eot?r1ump3');
  src: url('/assets/onts/venntur-icons/venntur-icons.eot?r1ump3#iefix') format('embedded-opentype'),
    url('/assets/fonts/venntur-icons/venntur-icons.ttf?r1ump3') format('truetype'),
    url('/assets/onts/venntur-icons/venntur-icons.woff?r1ump3') format('woff'),
    url('/assets/fonts/venntur-icons/venntur-icons.svg?r1ump3#venntur-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="vi-"],
[class*=" vi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'venntur-icons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.vi-save-as:before {
  content: "\e923";
  color: #fff;
}

.vi-save-as-2:before {
  content: "\e923";
  color: #999999;
}

.vi-google .path1:before {
  content: "\e90e";
  color: rgb(66, 133, 244);
}

.vi-google .path2:before {
  content: "\e90f";
  margin-left: -0.9814453125em;
  color: rgb(52, 168, 83);
}

.vi-google .path3:before {
  content: "\e910";
  margin-left: -0.9814453125em;
  color: rgb(250, 187, 5);
}

.vi-google .path4:before {
  content: "\e911";
  margin-left: -0.9814453125em;
  color: rgb(233, 66, 53);
}

.vi-cat-motor:before {
  content: "\e900";
}

.vi-cat-4:before {
  content: "\e900";
}

.vi-cat-aquatic:before {
  content: "\e902";
}

.vi-cat-9:before {
  content: "\e902";
}

.vi-cat-gastronomy:before {
  content: "\e904";
}

.vi-cat-7:before {
  content: "\e904";
}

.vi-cat-walking:before {
  content: "\e901";
}

.vi-cat-8:before {
  content: "\e901";
}

.vi-cat-tickets:before {
  content: "\e903";
}

.vi-cat-6:before {
  content: "\e903";
}

.vi-cat-adventure:before {
  content: "\e905";
}

.vi-cat-5:before {
  content: "\e905";
}

.vi-cat-sea:before {
  content: "\e906";
}

.vi-cat-2:before {
  content: "\e906";
}

.vi-cat-experiences:before {
  content: "\e907";
}

.vi-cat-3:before {
  content: "\e907";
}

.vi-cat-guided:before {
  content: "\e908";
}

.vi-cat-1:before {
  content: "\e908";
}

.vi-bubble:before {
  content: "\e909";
}

.vi-pin:before {
  content: "\e90a";
}

.vi-calendar:before {
  content: "\e90b";
}

.vi-clock:before {
  content: "\e90c";
}

.vi-photos:before {
  content: "\e90d";
}

.vi-facebook:before {
  content: "\e912";
  color: #337fff;
}

.vi-user-fill:before {
  content: "\e913";
  color: #ff5745;
}

.vi-user:before {
  content: "\e915";
}

.vi-whatsapp:before {
  content: "\e916";
  color: #ff5745;
}

.vi-email:before {
  content: "\e917";
  color: #ff5745;
}

.vi-mobile:before {
  content: "\e918";
  color: #ff5745;
}

.vi-web:before {
  content: "\e919";
  color: #ff5745;
}

.vi-search:before {
  content: "\e91a";
}

.vi-chevron-up:before {
  content: "\e91b";
}

.vi-chevron-down:before {
  content: "\e91c";
}

.vi-close:before {
  content: "\e91d";
}

.vi-filter:before {
  content: "\e91e";
}

.vi-list:before {
  content: "\e91f";
}

.vi-plane:before {
  content: "\e920";
}

.vi-heart-fill:before {
  content: "\e914";
  color: #ff5745;
}

.vi-heart:before {
  content: "\e921";
}

.vi-globe:before {
  content: "\e92a";
  color: #475467;
}

.vi-globe-crossed:before {
  content: "\e929";
  color: #475467;
}

.vi-content_copy:before {
  content: "\e924";
}

.vi-add:before {
  content: "\e925";
}

.vi-eye:before {
  content: "\e9ce";
}

.vi-info:before {
  content: "\ea0c";
}

.vi-sphere:before {
  content: "\e9c9";
}

.vi-cancel-circle:before {
  content: "\ea0d";
}

.vi-pdf:before {
  content: "\e926";
  color: #ff5745;
}

.vi-check:before {
  content: "\e928";
  color: #1ba292;
}

.vi-star-full:before {
  content: "\e9d9";
  color: #ff5745;
}

.vi-star-empty:before {
  content: "\e9d7";
  color: #ff5745;
}

.vi-star-half:before {
  content: "\e9d8";
  color: #ff5745;
}

.vi-thumbs-down:before {
  content: "\e92b";
}

.vi-thumbs-up:before {
  content: "\e92c";
}

.vi-sad:before {
  content: "\e9e5";
  color: #dc3545;
}

.vi-the-best-quality:before {
  content: "\e933";
}

.vi-what-people-love-more:before {
  content: "\e92e";
}

.vi-whats-new:before {
  content: "\e92d";
}

.vi-scroll:before {
  content: "\e92f";
}

.vi-icon-instagram:before {
  content: "\e932";
  color: #e5408f;
}

.vi-icon-facebook:before {
  content: "\e931";
  color: #e5408f;
}

.vi-icon-tik-tok:before {
  content: "\e930";
  color: #e5408f;
}

.vi-your-passion-is-our-passion:before {
  content: "\e937";
}

.vi-burger:before {
  content: "\e938";
  color: #ff5745;
}